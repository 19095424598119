import React from "react"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div style={{
        position: 'relative',
        display: 'block',
        width: '90%',
        height: 0,
        margin: 'auto',
        padding: '0% 0% 56.25%',
        overflow: 'hidden'
    }}>
    <iframe
        style={{position: 'absolute', top: 0, bottom: 0, left: 0, width: '100%', height: '100%', border: 0}}
        width="560"
        height="315"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
    />
  </div>
)
export default Video

            